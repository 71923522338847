@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Meiryo";
  src: url("/public/meiryo.woff2") format("woff2"),
    url("/public/meiryo.woff") format("woff"),
    url("/public/meiryo.ttc") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SpoqaHanSansNeo-Regular";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SpoqaHanSansNeo-Regular.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
